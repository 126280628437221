import Slider from './Slider';

export function registerMenu() {
    const buttons = document.querySelectorAll('.fake-menu-button > button');
    if (!buttons.length) return;

    document.addEventListener('click', event => {
        const menuClicked = Array.from(buttons).some(button =>
            button.contains(event.target) || button.nextElementSibling.contains(event.target));
        if (!menuClicked) {
            closeAll(buttons);
        }
    });

    const menuEl = document.querySelector('.slider-menu');
    if (!menuEl) return;

    const sliderButtons = document.querySelectorAll('.menu .slider-buttons button');
    const slider = Slider(menuEl, Array.from(sliderButtons));

    buttons.forEach((button, i) => {
        button.addEventListener('click', () => {
            slider.showWholeSlide(i);
            const isButtonOpened = isOpened(button);
            closeAll(buttons);
            toggle(button, !isButtonOpened);

            // disable slider snapping to the left item
            slider.animator.stop();

            if (!isButtonOpened) {
                const dropdown = button.parentElement.querySelector('.fake-menu-button__menu');
                if (!dropdown) return;
                dropdown.classList.remove('fake-menu-button__menu--reverse');

                if (dropdown.getBoundingClientRect().right > menuEl.getBoundingClientRect().right) {
                    dropdown.classList.add('fake-menu-button__menu--reverse');
                }
            }
        });
    });
}

function closeAll(buttons) {
    buttons.forEach(close);
}

function toggle(button, value) {
    const newValue = value === undefined ? !isOpened(button) : value;
    button.setAttribute('aria-expanded', newValue);
    button.classList.remove('btn--secondary', 'btn--primary');
    button.classList.add(newValue ? 'btn--primary' : 'btn--secondary');
}

function isOpened(button) {
    return button.getAttribute('aria-expanded') === 'true';
}

function close(button) {
    toggle(button, false);
}
