import Dropzone from 'dropzone'
import { compress } from './compress'

const MAX_FILES = 12
const MIN_FILE_SIZE_TO_COMPRESS = 2 * 1024 * 1024
const dropZoneSelector = '.dropzone-field'
let dropzone

export function uploadingInProgress() {
    return dropzone.getUploadingFiles().length > 0 || dropzone.getQueuedFiles().length > 0
}

export function isPhotoField(field) {
    return field.id === 'id_photos'
}

export function photoFieldValid(field) {
    return field.value.length > 0 && !uploadingInProgress()
}

export function photoUploader() {
    const csrf = document.querySelector('input[name=csrfmiddlewaretoken]')
    const photos = document.querySelector('input[name=photos]')
    const additionalPhotos = document.querySelector('input[name=additional_photos]')
    const errorMessageEl = document.querySelector('.field__description--attention[for=id_photos]')
    const isPendingPhotosEle = document.getElementById('is_photos_pending')

    const MAX_FILESIZE = 12
    dropzone = document.querySelector(dropZoneSelector) && new Dropzone(dropZoneSelector, {
        url: `${window.location.pathname}/api/photo/upload`,
        previewTemplate: document.querySelector('#custom-dz-preview').innerHTML,
        paramName: 'image',
        maxFilesize: MAX_FILESIZE,
        maxThumbnailFilesize: MAX_FILESIZE,
        maxFiles: MAX_FILES,
        timeout: 60000,
        acceptedFiles: '.png,.jpg,.jpeg',
        addRemoveLinks: true,
        previewsContainer: '.dropzone-previews',
        clickable: '.dropzone-upload',
        autoDiscover: false,
        thumbnailWidth: 286,
        thumbnailHeight: 286,
        thumbnailMethod: 'contain',
        dictRemoveFile: '',
        dictCancelUpload: '',
        dictFallbackMessage: 'Votre navigateur ne supporte pas le glisser/déposer de fichiers.',
        dictFallbackText: 'Veuillez utiliser le formulaire ci-dessous pour télécharger vos fichiers.',
        dictFileTooBig: 'Le fichier est trop lourd ({{filesize}}Mo). Taille maximun de fichier : {{maxFilesize}}Mo.',
        dictInvalidFileType: 'Vous ne pouvez pas envoyer ce type de fichier.',
        dictResponseError: 'Code réponse du serveur : {{statusCode}}.',
        dictCancelUploadConfirmation: 'Etes-vous sûr de vouloir annuler l\'envoi de ce fichier ?',
        dictMaxFilesExceeded: 'Vous ne pouvez pas envoyer plus d\'images. Maximum 12 images.',
        dictFileSizeUnits: { tb: 'To', gb: 'Go', mb: 'Mo', kb: 'ko', b: 'b' },
        init: displayImagesFromPendingInfoItem,
        uploadprogress: uploadProgressPicture,
        transformFile: compressFile
    })
        .on('sending', onSending)
        .on('success', onSuccess)
        .on('error', onError)
        .on('complete', onComplete)
        .on('queuecomplete', onComplete)
        .on('addedfile', onAddedFile)
        .on('removedfile', onRemovedFile)

    function displayImagesFromPendingInfoItem() {
        let isPendingInfoElem = document.getElementById('is_pending_info')
        let photosUrlElements = document.querySelectorAll('.images_item')
        let photosThumbnailElements = document.querySelectorAll('.images_thumbnail')
        let photosHashElements = document.querySelectorAll('.images_hash')
        let myDropzone = this
        if(isPendingInfoElem && isPendingInfoElem.value === 'true') {
            for (let indexPhotoEleStr in Array.from(photosUrlElements)) {
                let indexPhotoEle = parseInt(indexPhotoEleStr)
                let photoPath = photosUrlElements[indexPhotoEle].value
                let photoHash = photosHashElements[indexPhotoEle].value
                let fileServerPath = '/media/' + photoPath
                fetch(fileServerPath)
                    .then(res => res.blob())
                    .then(blob => {
                        let fileImage = { 
                            name: 'image' + indexPhotoEle,
                            size: blob.size,
                            type: blob.type,
                            serverPath: photoPath
                        }
                        myDropzone.files[indexPhotoEle] = fileImage
                        myDropzone.options.addedfile.call(myDropzone, fileImage)
                        myDropzone.options.thumbnail.call(myDropzone, fileImage, photosThumbnailElements[indexPhotoEle].value)
                        fileImage.previewElement.classList.add('dz-success')
                        fileImage.previewElement.classList.add('dz-complete')
                        addPhotoHash(fileImage, photoHash)
                        onComplete(fileImage)
                    })
            }
        }
    }

    function uploadProgressPicture(file, progress) {
        if (file && file.previewElement) {
            const circleBar = file.previewElement.querySelector('.progress-bar')
            if (circleBar) {
                const radius = circleBar.getAttribute('r')
                const circumference = Math.PI * (radius * 2)

                circleBar.style.strokeDashoffset = ((100 - progress) / 100) * circumference
            }
        }
    }

    function updateFormPhotoData(fileImage) {
        const previousImageHashes = Array.from(document.querySelectorAll('.images_hash'))
            .map(element => element.value)
        if (isRequestedPhotos()) {
            if(fileImage && !previousImageHashes
                .includes(fileImage.previewElement.querySelector('.dz-hash').innerHTML)) {
                const paths = dropzone.files
                    .filter(file => !previousImageHashes.includes(file.previewElement.querySelector('.dz-hash').innerHTML))
                    .map(file => file.serverPath)
                if(paths && paths.length > 0){
                    additionalPhotos.value = paths.join(',')
                } else {
                    additionalPhotos.value = ''
                }
            } else {
                const paths = dropzone.files
                    .filter(file => previousImageHashes.includes(file.previewElement.querySelector('.dz-hash').innerHTML))
                    .map(file => file.serverPath)
                photos.value = paths.join(',')
            } 
        } else {
            const paths = dropzone.files.map(file => file.serverPath)
            photos.value = paths.join(',')
        }
        photos.dispatchEvent(new Event('change', { bubbles: true }))
    }

    function onRemovedFile(file) {
        updateFormPhotoData(file)
    }

    function onComplete(file) {
        updateFormPhotoData(file)
        if (file) {
            removeLoadingImg(file)
            originalColorPicture(file)
        }
    }

    function onError(file, error, xhr) {
        if (xhr) { // bad response from server, reload
            document.location.reload()
        } else {
            this.removeFile(file)
            if (error !== 'Upload canceled.') {
                showError(error)
            }
        }
    }

    function onSuccess(file, res) {
        if (res.success) {
            if (existsPhoto(res.hash)) {
                showError('Vous ne pouvez pas télécharger une image existante')
                this.removeFile(file)
                return
            }
            file.serverPath = res.path
            addPhotoHash(file, res.hash)
            updateFormPhotoData(file)
        } else {
            if (res.errors && res.errors.image && res.errors.image.length > 0)
                showError(res.errors.image[0])
            else {
                showError('Il s\'est produit une erreur avec ce fichier, merci de réessayer.')
            }
            this.removeFile(file)
        }
    }

    function onAddedFile(file) {
        showProgressSpinner(file)
        blackAndWhitePicture(file)
        if (this.files.length > MAX_FILES) {
            showError('Vous ne pouvez pas télécharger plus de 12 photos')
            this.removeFile(file)
            return
        }
        photos.dispatchEvent(new Event('upload', {bubbles: true}))

        hideError()
    }

    function onSending(file, xhr, form) {
        xhr.ontimeout = () => {
            showError('L\'envoi du fichier a excédé la durée limite de 30s. '
                + 'Veuillez réduire le poids du fichier ou réessayer '
                + 'avec une connexion internet plus rapide.')
            this.removeFile(file)
        }
        form.append(csrf.name, csrf.value)
    }

    function showError(msg) {
        errorMessageEl.textContent = msg
    }

    function hideError() {
        errorMessageEl.textContent = ''
    }

    function removeLoadingImg(file) {
        let previewElement = file.previewElement
        if(previewElement) {
            let spinnerProgress = previewElement.querySelector('.spinner-element')
            for (let circle of spinnerProgress.children) {
                circle.classList.add('fade-out')
            }
        }
    }

    function blackAndWhitePicture(file) {
        let imgElem = file.previewElement.querySelector('.thumbnail-image')
        imgElem.classList.add('black-white')
    }

    function originalColorPicture(file) {
        let imgElem = file.previewElement.querySelector('.thumbnail-image')
        imgElem.classList.remove('black-white')
    }

    function showProgressSpinner(file) {
        let spinnerProgress = file.previewElement.querySelector('.progress-spinner-container')
        spinnerProgress.classList.remove('hidden')
    }

    function existsPhoto(fileHash){
        return Array.from(document.querySelectorAll('.dz-hash'))
            .map(hashElement => hashElement.innerHTML)
            .includes(fileHash)
    }

    function addPhotoHash(file, hash) {
        file.previewElement.querySelector('.dz-details > .dz-hash').textContent = hash
    }

    function isRequestedPhotos(){
        return isPendingPhotosEle ? isPendingPhotosEle.value === 'true' : false
    }
}

function compressFile(file, done) {
    if (file.size < MIN_FILE_SIZE_TO_COMPRESS) {
        return done(file)
    }

    compress(file, Dropzone.dataURItoBlob, done)
}
