import Slider from './Slider';

export function registerTabs() {
    let panelSlider;

    const tabsInstances = document.querySelectorAll('.tabs');
    if (!tabsInstances.length) return;

    tabsInstances.forEach(tabs => {
        const tabSliderButtons = tabs.parentElement.querySelectorAll('.slider-buttons button');
        const panelSliderButtons = tabs.querySelectorAll('.carousel__control');
        tabs.querySelectorAll('.tabs__item').forEach((tab, i) => {
            const tabSlider = Slider('.slider-tabs', tabSliderButtons, 16);
            tab.addEventListener('click', () => {
                tabSlider.showWholeSlide(i);
                panelSlider?.destroy();
                deselectAll(tabs);
                hideAllPanels(tabs);

                select(tab);
                const tabPanel = getPanelFromTab(tab);
                if (tabPanel) {
                    showPanel(tabPanel);
                    const imageList = tabPanel.querySelector('.slider-tabs-panels');
                    panelSlider = Slider(imageList, panelSliderButtons, 16);
                }
            });
            if (i === 0) {
                tab.click();
            }
        });
    });
}

function getPanelFromTab(tab) {
    return document.getElementById(tab.getAttribute('aria-controls'));
}

function select(tab) {
    tab.setAttribute('aria-selected', 'true');
    tab.classList.remove('btn--secondary');
}

function deselectAll(tabs) {
    tabs.querySelectorAll('.tabs__item').forEach(tab => {
        tab.setAttribute('aria-selected', 'false');
        tab.classList.add('btn--secondary');
    })
}

function showPanel(panel) {
    panel.removeAttribute('hidden');
}

function hideAllPanels(tabs) {
    tabs.querySelectorAll('.tabs__panel:not([hidden])').forEach(panel => panel.setAttribute('hidden', ''));
}
