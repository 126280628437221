import { isPhotoField, photoFieldValid } from './Photos'

export function registerValidators() {
    const form = document.getElementById('request-form')
    if (form) {
        const fields = Array.from(form.elements)
        const submitButton = form.querySelector('[type=submit]')
        const photoField = document.querySelector('input[name=photos]')

        validateOnSubmit(fields, submitButton)
        resetValidityOnChange(fields)
        hideBrowserPopups(fields)
        validatePendingInfoFields(fields)
        disableSubmitDuringPhotoUpload(photoField, submitButton)
    }
}

function disableSubmitDuringPhotoUpload(field, button) {
    if (field) {
        field.addEventListener('upload', () => {
            button.setAttribute('disabled', '')
        })
        let isPendingPhotosEle = document.getElementById('is_photos_pending')
        let isPendingPhotos = isPendingPhotosEle ? isPendingPhotosEle.value === 'true' : false
        field.addEventListener('change', () => {
            if (isPendingPhotos) {
                eventForPhotosField(button)
            } else if (isNotDescInfoReq()) {
                button.removeAttribute('disabled')
            }
        })
    }
}

function hideBrowserPopups(fields) {
    fields.forEach(field => field.addEventListener('invalid', e => e.preventDefault()))
}

function resetValidityOnChange(fields) {
    fields.forEach(field => {
        field.addEventListener('input', () => resetValidity(field))
        field.addEventListener('change', () => resetValidity(field))
    })
}

function validateOnSubmit(fields, submitButton) {
    submitButton.addEventListener('click', (event) => {
        fields.forEach(validateField)

        const invaliedFields = fields.filter(field => field.getAttribute('aria-invalid') === 'true')
        if (invaliedFields && invaliedFields.length > 0) {
            event.preventDefault()

            const photoInvalidField = invaliedFields.find(invalidField => invalidField.id=='id_photos')
            if (photoInvalidField) {
                let coordinates = absolutePosition(photoInvalidField.parentElement)
                window.scrollTo(coordinates.left, coordinates.top)
            }

            const firstInvalidField = invaliedFields.find(invalidField => invalidField.dataset.focusable)
            if (firstInvalidField) {
                firstInvalidField.focus()
            }
        }
    })
}

function resetValidity(field) {
    if (!isPhotoField(field) && !isNotDescInfoReq(field)) {
        hideErrorMessage(field)
        markAsValid(field)
    }
}

function validateField(field) {
    const isValid = isPhotoField(field) ? photoFieldValid(field) : field.validity.valid

    setErrorMessageState(field, !isValid)
    markValidity(field, isValid)
}

function hideErrorMessage(field) {
    setErrorMessageState(field, false)
}

function setErrorMessageState(field, isVisible) {
    if (field.id) {
        const errorMsgEl = document.querySelector(`.field__description--attention[for=${field.id}]`)
        if (errorMsgEl) {
            const msg = isPhotoField(field) ? 'Veuillez télécharger les photos de votre objet' : translateErrorMessage(field.validationMessage)
            errorMsgEl.textContent = isVisible ? msg : ''
        }
    }
}

function translateErrorMessage(errorMessage) {
    switch (errorMessage) {
    case 'Please fill in this field.':
    case 'Fill out this field':
    case 'Please enter a number.':
    case 'Enter a number':
        return 'Veuillez remplir ce champ.'
    case 'Please tick this box if you want to proceed.':
    case 'Please check this box if you want to proceed.':
    case 'Select this tickbox':
        return 'Accepter les conditions d’utilisation en cochant la case.'
    case 'Value must be greater than or equal to 0,1.':
    case 'Value must be greater than or equal to 0.1':
    case 'Please select a value that is no less than 0.1.':
        return 'La valeur doit être supérieure ou égale à 0,1.'
    case 'Value must be less than or equal to 999,99.':
    case 'Value must be less than or equal to 999.99':
    case 'Please select a value that is no more than 999.99.':
        return 'La valeur doit être inférieure ou égale à 999,99.'
    default:
        return errorMessage
    }
}

function markAsValid(field) {
    markValidity(field, true)
}

function markValidity(field, isValid) {
    field.setAttribute('aria-invalid', !isValid)

    if (field.id) {
        const label = document.querySelector(`.field__label[for=${field.id}]`)
        if (label) {
            if (isValid) {
                label.classList.remove('field__label--attention')
            } else {
                label.classList.add('field__label--attention')
            }
        }
    }
}

function isNotDescInfoReq() {
    let isPendingDescriptionEle = document.getElementById('is_description_pending')
    let isPendingDescription = isPendingDescriptionEle ? isPendingDescriptionEle.value === 'true' : false
    return !isPendingDescription
}

function validatePendingInfoFields() {
    let buttonUpdate = document.getElementById('update_info')
    let descriptInputField = document.getElementById('id_req_desc')
    let isPendingInfo = document.getElementById('is_pending_info') ? document.getElementById('is_pending_info').value === 'true' : false
    let isPendingDescriptionEle = document.getElementById('is_description_pending')
    let isPendingDescription = isPendingDescriptionEle ? isPendingDescriptionEle.value === 'true' : false
    let isPendingPhotosEle = document.getElementById('is_photos_pending')
    let isPendingPhotos = isPendingPhotosEle ? isPendingPhotosEle.value === 'true' : false
    if (isPendingInfo && (isPendingDescription || isPendingPhotos)) {
        buttonUpdate.setAttribute('disabled', '')
        if (isPendingDescription) {
            document.querySelector('div[for="id_req_desc"]').parentNode.classList.add('field__label--attention')
            descriptInputField.setAttribute('aria-invalid', 'true')
            descriptInputField.addEventListener('input', eventForDescriptionField)
        }
    }
}

function eventForDescriptionField() {
    let descriptInputField = document.getElementById('id_req_desc')
    let buttonUpdate = document.getElementById('update_info')
    let isPendingDescriptionEle = document.getElementById('is_description_pending')
    let req_additional_desc = document.getElementById('id_req_additional_desc')
    let initial_desc = document.getElementById('initial_desc')
    req_additional_desc.value = descriptInputField.value.replace(initial_desc.value, '').trim()
    if (descriptInputField.value.includes(initial_desc.value)
        && isNewValueGreater(descriptInputField.value, initial_desc.value)) {
        isPendingDescriptionEle.value = 'false'
        descriptInputField.setAttribute('aria-invalid', 'false')
        document.querySelector('div[for="id_req_desc"]').parentNode.classList.remove('field__label--attention')
        buttonUpdate.removeAttribute('disabled')
    } else {
        isPendingDescriptionEle.value = 'true'
        descriptInputField.setAttribute('aria-invalid', 'true')
        descriptInputField.value = initial_desc.value + ' '
        document.querySelector('div[for="id_req_desc"]').parentNode.classList.add('field__label--attention')
        buttonUpdate.setAttribute('disabled', '')
    }
}

function isNewValueGreater(newValue, initialValue) {
    return removeSpaceAndSymbols(newValue).length > removeSpaceAndSymbols(initialValue).length
}

function removeSpaceAndSymbols(text) {
    return text.replace(/[^A-Z0-9]+/ig, '')
}

function eventForPhotosField() {
    let buttonUpdate = document.getElementById('update_info')
    let additionalPhotosElement = document.querySelector('input[name=additional_photos]')
    if (additionalPhotosElement && additionalPhotosElement.value && additionalPhotosElement.value.split(',').length > 0) {
        buttonUpdate.removeAttribute('disabled')
    } else {
        buttonUpdate.setAttribute('disabled', '')
    }
}

function absolutePosition(elem) { // crossbrowser version
    var box = elem.getBoundingClientRect()

    var body = document.body
    var docEl = document.documentElement

    var scrollTop = window.pageYOffset || docEl.scrollTop || body.scrollTop
    var scrollLeft = window.pageXOffset || docEl.scrollLeft || body.scrollLeft

    var clientTop = docEl.clientTop || body.clientTop || 0
    var clientLeft = docEl.clientLeft || body.clientLeft || 0

    var top = box.top + scrollTop - clientTop
    var left = box.left + scrollLeft - clientLeft

    return { top: Math.round(top), left: Math.round(left) }
}

