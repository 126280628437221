export function showModal(modalId) {
    const modal = document.getElementById(modalId)
    if (modal) {
        modal.removeAttribute('hidden')
        registerModal(modal)
    } else {
        console.warn('Modal not found by id:', modalId)
    }
}

export function registerModal(modal){
    modal.addEventListener('click', function(event) {
        if (event.target === modal) {
            closeModal(modal)
        }
    })

    const button = modal.querySelector('.fullscreen-dialog__close')
    if (button) {
        button.addEventListener('click', function(event) {
            if (event.target === button) {
                closeModal(modal)
            }
        })
    }

    document.addEventListener('keydown', ({key}) => {
        if (key === 'Escape') {
            closeModal(modal)
        }
    })
}

function closeModal(modal) {
    modal.setAttribute('hidden', 'hidden')
}
