import svg4everybody from 'svg4everybody'
import { commonComponents } from './pages/common'
import { requestEstimationPage } from './pages/requestEstimationPage'
import { faqPage } from './pages/faqPage'
import { confirmedEstimationPage } from './pages/confirmedEstimationPage'
import { homePage } from './pages/homePage';

svg4everybody()
commonComponents()
requestEstimationPage()
faqPage()
confirmedEstimationPage()
homePage()
