import KeenSlider from 'keen-slider';

export default (container = '.keen-slider', buttons, spacing = 8) => {
    const [buttonPrev, buttonNext] = buttons || [];

    const slider = new KeenSlider(
        container,
        {
            renderMode: 'performance',
            created: (slider) => {
                setTimeout(() => {
                    // workaround to fix last item position
                    slider.update();
                }, 1000);
                updateButtons(slider);
            },
            destroyed: () => {
                buttonPrev.removeEventListener('click', handleSliderPrev);
                buttonNext.removeEventListener('click', handleSliderNext);
            },
            slideChanged: (slider) => {
                updateButtons(slider);
            },
            selector: '.keen-slider__slide',
            slides: {
                perView: 'auto',
                spacing,
            },
        }
    );

    slider.showWholeSlide = idx => {
        const slide = slider.track.details.slides[idx];
        if (!slide) return;

        if (slide.distance < 0) {
            slider.track.add(slide.distance);
            updateButtons(slider);
        } else {
            const hiddenSlidePart = 1 - slide.portion;
            const viewPortSize = slide.size;
            const delta = hiddenSlidePart * viewPortSize;
            if (delta > 0) {
                slider.track.add(delta);
                updateButtons(slider);
            }
        }
    }

    function updateButtons(slider) {
        if (buttons) {
            const currentIndex = slider.track.details.abs;
            const min = 0;
            const max = slider.track.details.maxIdx;
            const prevDisabled = currentIndex === min;
            buttonPrev.setAttribute('aria-disabled', prevDisabled ? 'true' : 'false');
            const nextDisabled = currentIndex === max;
            buttonNext.setAttribute('aria-disabled', nextDisabled ? 'true' : 'false');
        }
    }

    const handleSliderPrev = () => slider.moveToIdx(prevPageIndex(slider));
    const handleSliderNext = () => slider.moveToIdx(nextPageIndex(slider));

    if (buttons) {
        buttonPrev.addEventListener('click', handleSliderPrev);
        buttonNext.addEventListener('click', handleSliderNext);
    }

    return slider;
}

function prevPageIndex(slider) {
    const prevIndex = slider.track.distToIdx(-1);
    return prevIndex === null ? 0 : prevIndex;
}

function nextPageIndex(slider) {
    const nextIndex = slider.track.distToIdx(1);
    return nextIndex === null ? slider.track.details.maxIdx : nextIndex
}
