import { registerExpandable } from '../components/Expandable'
import { registerShowCookiesBanner } from '../components/ShowCookiesBanner'
import { showModal } from '../components/Modal'

export function commonComponents() {
    registerExpandable()
    registerShowCookiesBanner()
    registerModalCategories()
    registerContactEstimationConfirmation()
    registerUpdateRequestInformationPopup()
    registerIdoNotHaveInfoPopup()
}

function registerModalCategories() {
    document.querySelectorAll('.show__modal-categories').forEach(button => {
        button.addEventListener('click', () => {
            showModal('modal-categories')
        })
    })
}

function registerContactEstimationConfirmation() {
    const confirmIt = e => {
        if (!confirm('Contactez France Estimations.\n\nEn envoyant un email, vous acceptez de partager vos données personnelles avec France Estimations afin d’être accompagné dans l’expertise et la vente de votre objet.')) {
            e.preventDefault()
        }
    }
    document.querySelectorAll('.contact-estimation').forEach(elem => {
        elem.addEventListener('click', confirmIt, false)
    })
}

function registerUpdateRequestInformationPopup() {
    const alertIt = () =>
        alert('Merci d’avoir ajouté ces informations.\n\nGrâce à cela, nos experts de France Estimations vont être en mesure d’estimer votre objet et vous répondre sous 48h ouvrées.')

    const update_info_button = document.getElementById('update_info')
    if (update_info_button) {
        update_info_button.addEventListener('click', alertIt, false)
    }
}

function registerIdoNotHaveInfoPopup() {
    const alertIt = () =>
        alert('Nous reviendrons vers vous.\n\nNos experts de France Estimations reviendront vers vous sous 48h ouvrées.')

    const not_info_button = document.getElementById('button-not-info')
    if (not_info_button) {
        not_info_button.addEventListener('click', alertIt, false)
    }
}
