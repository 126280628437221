// noinspection JSSuspiciousNameCombination

const EXIF_UNDEFINED = -1
const ERROR_NOT_JPG = -2
const JPG_TYPE = 0xFFD8
const PNG_TYPE = 0x8950

export function compress(file, dataURItoBlob, callback) {
    const reader = new FileReader()

    reader.addEventListener('loadend', () => {
        const img = new Image()

        img.onload = () => {
            const cvs = document.createElement('canvas')

            orientationFromFile(file, orientation => {
                if (orientation > ERROR_NOT_JPG) {
                    drawInCanvas(img, cvs, orientation)
                }
                const zipImg = cvs.toDataURL('image/jpeg', 75 / 100)

                callback(dataURItoBlob(zipImg))
            })
        }

        img.src = reader.result
    })

    reader.readAsDataURL(file)
}

function drawInCanvas(image, canvas, orientation) {
    const ctx = canvas.getContext('2d')

    if (!ctx) { return }

    let x = 0
    let y = 0

    ctx.save()

    const DEG_0 = 1
    const DEG_0_FLIP = 2
    const DEG_180 = 3
    const DEG_180_FLIP = 4
    const DEG_90_FLIP = 5
    const DEG_270 = 6
    const DEG_270_FLIP = 7
    const DEG_90 = 8

    switch (orientation) {
    case DEG_0_FLIP:
        x = -canvas.width
        ctx.scale(-1, 1)
        break
    case DEG_180:
        x = -canvas.width
        y = -canvas.height
        ctx.scale(-1, -1)
        break
    case DEG_180_FLIP:
        y = -canvas.height
        ctx.scale(1, -1)
        break
    case DEG_90_FLIP:
        canvas.width = image.naturalHeight
        canvas.height = image.naturalWidth
        ctx.translate(canvas.width, canvas.height / canvas.width)
        ctx.rotate(Math.PI / 2)
        y = -canvas.width
        ctx.scale(1, -1)
        break
    case DEG_270:
        canvas.width = image.naturalHeight
        canvas.height = image.naturalWidth
        ctx.translate(canvas.width, canvas.height / canvas.width)
        ctx.rotate(Math.PI / 2)
        break
    case DEG_270_FLIP:
        canvas.width = image.naturalHeight
        canvas.height = image.naturalWidth
        ctx.translate(canvas.width, canvas.height / canvas.width)
        ctx.rotate(Math.PI / 2)
        x = -canvas.height
        ctx.scale(-1, 1)
        break
    case DEG_90:
        canvas.width = image.naturalHeight
        canvas.height = image.naturalWidth
        ctx.translate(canvas.width, canvas.height / canvas.width)
        ctx.rotate(Math.PI / 2)
        x = -canvas.height
        y = -canvas.width
        ctx.scale(-1, -1)
        break
    case DEG_0:
        // fallthrough
    default:
        canvas.width = image.naturalWidth
        canvas.height = image.naturalHeight
    }
    ctx.drawImage(image, x, y)
    ctx.restore()
}

function orientationFromFile(file, callback) {
    const reader = new FileReader()

    reader.addEventListener('loadend', e =>
        callback(orientationFromView(new DataView(e.target.result))))
    reader.readAsArrayBuffer(file)
}

function orientationFromView(view) {
    if (view.getUint16(0, false) !== JPG_TYPE && view.getUint16(0, false) !== PNG_TYPE) {
        return ERROR_NOT_JPG
    }

    const length = view.byteLength
    let offset = 2

    while (offset < length) {
        if (view.getUint16(offset+2, false) <= 8) {
            return EXIF_UNDEFINED
        }

        const marker = view.getUint16(offset, false)
        offset += 2

        if (marker === 0xFFE1) {
            if (view.getUint32(offset += 2, false) !== 0x45786966) {
                return EXIF_UNDEFINED
            }

            const little = view.getUint16(offset += 6, false) === 0x4949
            offset += view.getUint32(offset + 4, little)

            const tags = view.getUint16(offset, little)
            offset += 2

            for (let i = 0; i < tags; i++) {
                if (view.getUint16(offset + (i * 12), little) === 0x0112) {
                    return view.getUint16(offset + (i * 12) + 8, little)
                }
            }
        } else if ((marker & 0xFF00) !== 0xFF00) {
            break
        } else {
            offset += view.getUint16(offset, false)
        }
    }
    return EXIF_UNDEFINED
}