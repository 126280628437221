export function registerExpandable() {
    const detailsList = document.querySelectorAll('details')

    detailsList.forEach(details => {
        details.addEventListener('toggle', () => {
            if (details.open) {
                collapseOthers(detailsList, details)
            }
        })
    })
}

const collapseOthers = (detailsList, excluded) => {
    detailsList.forEach(details => {
        if (details !== excluded) {
            details.removeAttribute('open')
        }
    })
}
