const MONTH = 30 * 24 * 60 * 60 * 1000
const EXPIRATION = 14 * MONTH

export function registerShowCookiesBanner() {
    const acceptcookiesbanner = document.getElementById('accept-cookies-banner')
    if (acceptcookiesbanner) {
        acceptcookiesbanner.addEventListener('click', hideBannerAndAcceptAnalytics)
    }

    const savecookiespreferences = document.getElementById('save_cookies_preferences')
    if (savecookiespreferences) {
        savecookiespreferences.addEventListener('click', hideBannerAndSaveCookie)
    }

    const rejectcookiesbanner = document.getElementById('reject-cookies-banner')
    if (rejectcookiesbanner) {
        rejectcookiesbanner.addEventListener('click', hideBannerAndRejectCookie)
    }

    const inCookiesPrefPage = document.getElementById('cookies_preferences_page')
    if (inCookiesPrefPage) {
        hideCookieBanner()
    }
}

function hideBannerAndAcceptAnalytics() {
    hideBannerAndSaveCookie()
    acceptGoogleAnalytics()
}

function hideBannerAndRejectCookie() {
    hideBannerAndSaveCookie()
    rejectGoogleAnalytics()
}

function hideCookieBanner() {
    const banner = document.getElementById('cookie_banner')
    if (banner) {
        banner.style.display = 'none'
    }
}

function hideBannerAndSaveCookie() {
    document.cookie = `hide_cookie_banner=hide ${getExpiration()}`
    hideCookieBanner()
}

function acceptGoogleAnalytics() {
    document.cookie = `consent=1; ${getExpiration()}`
    location.reload()
}

function rejectGoogleAnalytics() {
    document.cookie = `consent=0; ${getExpiration()}`
    location.reload()
}

function getExpiration() {
    const d = new Date()
    d.setTime(d.getTime() + EXPIRATION)
    return `expires=${d.toUTCString()}`
}
